import styled from 'styled-components';
import React from 'react';

import { UaopFlags } from '../../DryRunDialog';
import { exhaustiveCheck } from '../../../../utils/utils';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.4);
`;

export const Paragraph = styled.p`
    font-weight: lighter;
    font-size: 14px;
    font-style: italic;
`;

export const CheckBoxContainer = styled.div<{ marginTop?: string }>`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || '25px'};
`;

type ConfirmationTypes =
    | 'allDetailsCorrect'
    | 'uaopUserManual'
    | 'householdFocalPointMapping'
    | 'individualLevelAssistance'
    | 'assistanceFileAsPerTemplate'
    | 'onlyHouseholdFocalPointTIDIncluded'
    | 'assistancePeriodCorrect'
    | 'multipliedAssistanceAmountPerPersonPerMonth'
    | 'clarifiedQuestionsWithBBTeam'
    | 'whenHistoricalSelected'
    | 'whenIncrementalShiftPeriod'
    | 'bePatient';

interface ConfirmationItem {
    name: ConfirmationTypes;
    content: JSX.Element;
}

type ConfirmationsModel = {
    [P in ConfirmationTypes]: ConfirmationItem;
};

export const CONFIRMATIONS: ConfirmationsModel = {
    allDetailsCorrect: {
        name: 'allDetailsCorrect',
        content: <>All the above details are correct.</>,
    },
    uaopUserManual: {
        name: 'uaopUserManual',
        content: (
            <>
                You have thoroughly read and understood the latest version of the UAOP user manual available in the
                “Documents” section.
            </>
        ),
    },
    individualLevelAssistance: {
        name: 'individualLevelAssistance',

        content: (
            <>
                Assistance in your file is at the individual household member level. This means you have only included
                the targeted individual household member TIDs in your assistance file.
            </>
        ),
    },
    householdFocalPointMapping: {
        name: 'householdFocalPointMapping',
        content: (
            <>
                If you wish to avail of the household focal point to the household member TID mapping feature, you have
                already loaded the latest version of your mapping according to your organization using the newest
                template available in the “Documents” section.
            </>
        ),
    },
    assistanceFileAsPerTemplate: {
        name: 'assistanceFileAsPerTemplate',
        content: (
            <>You have prepared your assistance file using the latest template available in the “Documents” section.</>
        ),
    },
    onlyHouseholdFocalPointTIDIncluded: {
        name: 'onlyHouseholdFocalPointTIDIncluded',
        content: (
            <>
                Assistance in your file is at the household focal point TID level on behalf of the entire household (and
                not the individual household member level). This means you have only included household focal point TIDs
                in your assistance file.
            </>
        ),
    },
    assistancePeriodCorrect: {
        name: 'assistancePeriodCorrect',
        content: <>The assistance period(s) in your file is/are correct.</>,
    },
    multipliedAssistanceAmountPerPersonPerMonth: {
        name: 'multipliedAssistanceAmountPerPersonPerMonth',
        content: <>The assistance amount stated is the total amount for the assistance period selected.</>,
    },
    clarifiedQuestionsWithBBTeam: {
        name: 'clarifiedQuestionsWithBBTeam',
        content: <>You have clarified any questions you may have with the BB team (ukraine.buildingblocks@wfp.org).</>,
    },
    whenHistoricalSelected: {
        name: 'whenHistoricalSelected',
        content: (
            <>
                You are loading your assistance file as close as possible to the time when you will make the assistance
                available to the households.
            </>
        ),
    },
    whenIncrementalShiftPeriod: {
        name: 'whenIncrementalShiftPeriod',
        content: (
            <>
                If your assistance period start date is shifted to the future (possible with the “Incremental (Shift
                Period)” UAOP option, commit to ensuring your FSP makes the assistance available to households as close
                as possible to the newly indicated start date. Note: for this UAOP option, the assistance period must be
                the same for all the rows in your file.
            </>
        ),
    },
    bePatient: {
        name: 'bePatient',
        content: (
            <>
                If your file is not executed immediately, you will kindly be patient and wait for it to start executing,
                and you will not load the same file again just because a previous file you have loaded has not started
                executing yet.
            </>
        ),
    },
};

export const commonConfirmations = [
    CONFIRMATIONS.allDetailsCorrect,
    CONFIRMATIONS.uaopUserManual,
    CONFIRMATIONS.householdFocalPointMapping,
    CONFIRMATIONS.assistanceFileAsPerTemplate,
    CONFIRMATIONS.onlyHouseholdFocalPointTIDIncluded,
    CONFIRMATIONS.assistancePeriodCorrect,
    CONFIRMATIONS.multipliedAssistanceAmountPerPersonPerMonth,
    CONFIRMATIONS.clarifiedQuestionsWithBBTeam,
    CONFIRMATIONS.bePatient,
];

export const commonHouseholdLevelWinterCategoryConfirmations = [
    CONFIRMATIONS.allDetailsCorrect,
    CONFIRMATIONS.uaopUserManual,
    CONFIRMATIONS.householdFocalPointMapping,
    CONFIRMATIONS.assistanceFileAsPerTemplate,
    CONFIRMATIONS.onlyHouseholdFocalPointTIDIncluded,
    CONFIRMATIONS.assistancePeriodCorrect,
    CONFIRMATIONS.clarifiedQuestionsWithBBTeam,
    CONFIRMATIONS.bePatient,
];

export const commonIndividualLevelWinterCategoryConfirmations = [
    CONFIRMATIONS.allDetailsCorrect,
    CONFIRMATIONS.uaopUserManual,
    CONFIRMATIONS.assistanceFileAsPerTemplate,
    CONFIRMATIONS.individualLevelAssistance,
    CONFIRMATIONS.assistancePeriodCorrect,
    CONFIRMATIONS.clarifiedQuestionsWithBBTeam,
    CONFIRMATIONS.bePatient,
];

export type HouseholdExclusionType = 'NONE' | 'ONE_MONTH' | 'TWO_MONTHS' | 'THREE_MONTHS';

export type HouseholdExclusionModel = {
    [P in HouseholdExclusionType]: {
        id: P;
        label: JSX.Element;
        value: Readonly<string | number>;
        disabled?: boolean;
        visibleName: Readonly<string>;
    };
};

export const HouseholdExclusionModelImpl: HouseholdExclusionModel = {
    NONE: {
        id: 'NONE',
        label: <span className={'font-weight-bold'}>No.</span>,
        value: 'none',
        visibleName: 'none',
    },
    ONE_MONTH: {
        id: 'ONE_MONTH',
        label: (
            <>
                <span className={'font-weight-bold'}>One Month: </span> Yes, exclude households with existing assistance
                in the selected categories anytime during the 1 month before my assistance start date.
            </>
        ),
        value: 1,
        visibleName: '1 month',
    },
    TWO_MONTHS: {
        id: 'TWO_MONTHS',
        label: (
            <>
                <span className={'font-weight-bold'}>Two Months: </span> Yes, exclude households with existing
                assistance in the selected categories anytime during the 2 months before my assistance start date.
            </>
        ),
        value: 2,
        visibleName: '2 months',
    },
    THREE_MONTHS: {
        id: 'THREE_MONTHS',
        label: (
            <>
                {' '}
                <span className={'font-weight-bold'}>Three Months: </span>Yes, exclude households with existing
                assistance in the selected categories anytime during the 3 months before my assistance start date.
            </>
        ),
        value: 3,
        visibleName: '3 months',
    },
} as const;

export const mapStrategyFlag = (flag: UaopFlags) => {
    switch (flag) {
        case UaopFlags.NoneHistorical:
        case UaopFlags.IncrementalShiftPeriod:
        case UaopFlags.IncrementalDeltaAmountYesOption:
        case UaopFlags.IncrementalAnyAmount:
        case UaopFlags.IncrementalDeltaAmountNoOption:
        case UaopFlags.TotalAnyAmount:
        case UaopFlags.TotalDeltaAmount:
        case UaopFlags.TotalDeltaAmountWithShift:
        case UaopFlags.NoneIntendedAssistanceOverlap:
        case UaopFlags.TotalDeltaAmountV2:
        case UaopFlags.IncrementalDeltaAmountV2:
            return flag.replace(/[A-Z]/g, ' $&').trim();
        default:
            exhaustiveCheck(flag);
    }
};
